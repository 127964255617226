<template>
	<Modal :title="title" v-model="show" class-name="vertical-center-modal" @on-cancel="cancel" :loading="loading"
		:mask-closable="false" :footer-hide="true">
		<Form :model="formItem" :label-width="80" :rules="ruleFormItem" ref="form">
			<FormItem label="支付金额" prop="amount">
				<Input v-model="formItem.amount" type="number" placeholder="请输入支付金额"></Input>
			</FormItem>

			<FormItem style="text-align: right;">
				<Button type="primary" @click="cancel">取消</Button>
				<Button @click="save" style="margin-left: 8px">提交</Button>
			</FormItem>

		</Form>
	</Modal>
</template>
<script>
	import {
		weChatMerchantPaymentConfigUpdate
	} from '@/api/merchant/merchant.js'
	export default {
		name: "UpdateRole",
		data() {
			return {
				title: "修改金额配置",

				formItem: {
					amount: '', //金额
				},
				ruleFormItem: {
					amount: [{
						required: true,
						message: '金额不得为空',
						trigger: 'blur'
					}],

				},
				show: false,
				loading: false

			}
		},
		props: {
			updateShow: {
				type: Boolean,
				default: true,
			},
			updateData: {}
		},
		methods: {
			cancel() {
				this.$parent.updateShow = false
				this.$parent.updateData = {};
			},
			save() {
				this.$refs.form.validate((valid) => {
					if (valid) {
						console.log(valid)
						this.loading = true
						weChatMerchantPaymentConfigUpdate(this.formItem).then(() => {
							this.$Message.info({
								content: '修改成功'
							});
							this.$parent.updateData = {};
							this.$parent.loadData();
							this.loading = false;
							this.cancel()
						}).catch(() => {
							this.loading = false
						})
					}
				})
			}

		},
		watch: {
			updateShow() {
				this.show = this.updateShow;
			},
			show() {
				if (!this.show) {
					this.formItem = {
						amount: '', //	金额
						weChatMerchantConfigUuid: '', //微信uuid
					};
					this.$parent.updateShow = this.show;
				}
			},
			updateData() {
				Object.assign(this.formItem, this.updateData)
			},

		}
	}
</script>

<style scoped>
	.vertical-center-modal {
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.ivu-modal {
		top: 0;
	}
</style>