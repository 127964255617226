<template>
	<Modal :title="title" v-model="show" class-name="vertical-center-modal" @on-cancel="cancel"
		:loading="loading" :mask-closable="false" :footer-hide="true"	>
		<Form :model="formItem" :label-width="80" :rules="ruleFormItem" ref="form" >
			<FormItem label="支付金额" prop="amount">
				<Input v-model="formItem.amount" type="number" placeholder="请输入支付金额"></Input>
			</FormItem>
			
			<FormItem style="text-align: right;">
				<Button type="primary" @click="cancel">取消</Button>
				<Button @click="save" style="margin-left: 8px">提交</Button>
			</FormItem>
			
		</Form>
	</Modal>
</template>
<script>
	import {
		weChatMerchantPaymentConfigSave
	} from '@/api/merchant/merchant.js'
	export default {
		name: "AddRole",
		data() {
			return {
				title: "新增金额配置",

				formItem: {
					amount:'', //金额
				},
				ruleFormItem: {
					amount: [{
						required: true,
						message: '金额不得为空',
						trigger: 'blur'
					}],
					
				},
				show: false,
				loading: false
			}
		},
		props: {
			addShow: {
				type: Boolean,
				default: true,
			}
		},
		methods: {
			cancel() {
				this.$parent.addShow = false
			},
			save() {
				this.$refs.form.validate((valid) => {
					if (valid) {
						this.loading = true
						this.formItem.weChatMerchantConfigUuid=JSON.parse(sessionStorage.getItem('merchantInfo')).uuid
						weChatMerchantPaymentConfigSave(this.formItem).then(() => {
							this.$Message.info({
								content: '保存成功'
							});
							this.$parent.loadData();
							this.loading = false;
							this.cancel()
						}).catch(() => {
							this.loading = false
						})
					} 
				})

			}
		},
		watch: {
			addShow() {
				this.show = this.addShow;
			},
			show() {
				this.formItem = {
					amount: '', //	金额
					weChatMerchantConfigUuid: '', //微信uuid
				};
				this.$parent.addShow = this.show;
			}
		}
	}
</script>

<style scoped>
	.vertical-center-modal {
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.ivu-modal {
		top: 0;
	}
</style>