<template>
	<div>
		<Row>
			<Col span="24" style="text-align: right;margin-bottom: 20px;">
			<Button type="primary" class="margin_left_10" @click.native="showAddmerchant">新增</Button>
			</Col>
		</Row>
		<Table :columns="columns" :data="data">

			<template slot-scope="{ row }" slot="action">
				<Button size="small" type="primary" @click.native="updatemerchant(row)"
					class="margin_right_10">修改</Button>

				<Button size="small" type="error" @click.native="delmerchant(row)" class="margin_right_10">删除</Button>
			</template>
		</Table>

		<AddPayConfig :addShow="addShow"></AddPayConfig>
		<UpdatePayConfig :updateShow="updateShow" :updateData="updateData"></UpdatePayConfig>

	</div>
</template>
<script>
	import AddPayConfig from './AddPayConfig.vue';
	import UpdatePayConfig from './UpdatePayConfig.vue';

	import {
		weChatMerchantPaymentConfigList,
		weChatMerchantPaymentConfigDel,
	} from '@/api/merchant/merchant.js'
	export default {
		name: 'payConfig',
		components: {
			AddPayConfig,
			UpdatePayConfig,

		},
		data() {
			return {
				addShow: false,
				updateShow: false,
				updateData: '',
				columns: [{
						title: '支付金额',
						key: 'amount'
					},

					{
						title: '创建时间',
						key: 'updatedAt'
					},
					{
						title: '更新人',
						key: 'updater'
					},
					{
						title: '操作',
						slot: 'action'
					}
				],
				data: []
			}
		},
		created() {
			this.loadData();
		},
		methods: {
			loadData: function() {
				console.log()
				let _search = {
					appid: JSON.parse(sessionStorage.getItem('merchantInfo')).appid
				}
				weChatMerchantPaymentConfigList(_search).then(res => {
					console.log(res)
					this.data = res.data.data

				}).catch(reason => {
					console.log(reason);
				})
			},
			changePage(pageNum) {
				this.page.pageNum = pageNum;
				this.loadData();
			},
			showAddmerchant() {
				this.addShow = true;
			},

			delmerchant(item) {
				this.$confirm('此操作将删除商户, 是否继续?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					weChatMerchantPaymentConfigDel({
						uuid: item.uuid
					}).then(() => {
						this.loadData();
					}).catch(reason => {
						console.log(reason);
					})
				}).catch(() => {
					this.$message({
						type: 'info',
						message: '已取消删除操作'
					});
				});
			},
			updatemerchant(data) {
				this.updateShow = true;
				this.updateData = data;
			}
		}
	}
</script>

<style>
</style>